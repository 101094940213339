import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import {
  KnowledgeNugget,
  KnowledgeNuggetBadge,
  Evaluation,
  IkInfo,
  KeyPhraseResult,
  ChallengeInfo,
  Idea,
  KMSObject,
  ChallengeBadge,
  Challenge,
  ImpactingKnowledgeType,
  IdeaInfo,
  ImageEntityTypes,
  ImageType,
  User,
  KnowledgeNuggetDiffusionRating
} from "../shared/entities";
import { map, shareReplay, filter, switchMap, catchError } from "rxjs/operators";
import { UploadService } from "./upload.service";
import { KMSSearchResults, SearchService } from "./search.service";
import { ConfigurationService } from "./configuration.service";

@Injectable({
  providedIn: "root"
})
export class IkService {
  private defaultIKAvatar$: Observable<string>;
  private defaultIKThumbnailAvatar$: Observable<string>;

  private defaultIdeaAvatar$: Observable<string>;
  private defaultWebinarAvatar$: Observable<string>;
  private defaultSuccessStoryAvatar$: Observable<string>;
  private defaultChallengeAvatar$: Observable<string>;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly uploaderSvc: UploadService,
    private readonly searchSvc: SearchService,
    private readonly configSvc: ConfigurationService
  ) {
    this.configSvc.assetsConfig.pipe(filter(r => r != null)).subscribe(() => {
      this.defaultIKAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.KnowledgeNugget,
        ImageType.Avatar,
        `portal/api/ik/knowledgenugget/avatar/default/get`
      );

      this.defaultIKThumbnailAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.KnowledgeNugget,
        ImageType.Thumbnail,
        `portal/api/ik/knowledgenugget/avatar/default/thumbnail/get`
      );

      this.defaultIdeaAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.Idea,
        ImageType.Avatar,
        `portal/api/ik/idea/avatar/default/get`
      );

      this.defaultWebinarAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.Webinar,
        ImageType.Avatar,
        `webinar/api/ik/webinar/avatar/default/get`
      );

      this.defaultSuccessStoryAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.SuccessStory,
        ImageType.Avatar,
        `portal/api/ik/successstory/avatar/default/get`
      );

      this.defaultChallengeAvatar$ = this.configSvc.getDefaultImage(
        ImageEntityTypes.Challenge,
        ImageType.Avatar,
        `portal/api/ik/challenge/avatar/default/get`
      );
    });
  }

  public getDefaultIKAvatar(type = KMSObject.IK): Observable<any> {
    switch (type) {
      case KMSObject.IK:
        return this.defaultIKAvatar$;
      case KMSObject.Idea:
        return this.defaultIdeaAvatar$;
      case KMSObject.Webinar:
        return this.defaultWebinarAvatar$;
      case KMSObject.SuccessStory:
        return this.defaultSuccessStoryAvatar$;
      case KMSObject.Challenge:
        return this.defaultChallengeAvatar$;
    }
  }

  public getDefaultIKThumbnail(type = KMSObject.IK): Observable<string> {
    return this.defaultIKThumbnailAvatar$;
  }

  public uploadIkAvatar(
    file: File,
    sourcePostId: string,
    type = KMSObject.IK
  ): Observable<string> {
    const url =
      this.getBaseUrl(type) +
      `${encodeURIComponent(sourcePostId)}/avatar/upload`;
    // let url = "";
    // if (type === KMSObject.IK) {
    //   url = `portal/api/ik/knowledgenugget/${encodeURIComponent(
    //     sourcePostId
    //   )}/avatar/upload`;
    // } else if (type === KMSObject.Idea) {
    //   url = `portal/api/ik/idea/${encodeURIComponent(sourcePostId)}/avatar/upload`;
    // } else if (type === KMSObject.Challenge) {
    //   url = `portal/api/ik/challenge/${encodeURIComponent(sourcePostId)}/avatar/upload`;
    // } else if (type === KMSObject.SuccessStory) {
    //   url = `portal/api/ik/successstory/${encodeURIComponent(sourcePostId)}/avatar/upload`;
    // }

    return this.uploaderSvc.uploadImage(url, file);

    // const formData = new FormData();
    // formData.append("files", file);
    // return this.httpClient.post<string>(
    //   `portal/api/ik/${encodeURIComponent(sourcePostId)}/avatar/upload`,
    //   formData
    // );
  }

  public createWithEvaluation(
    item: string,
    evaluation: string,
    type: KMSObject
  ) {
    const url = this.getBaseUrl(type) + `createwithevaluation`;
    return this.httpClient.post<string>(url, {
      item1: item,
      item2: evaluation
    });
  }

  public delete(id: string, type: KMSObject, reason: string) {
    let url = this.getBaseUrl(type) + `${id}`;
    if(reason && reason.trim() !== ""){
      url = url + `?demoteReason=${reason}`
    }
    return this.httpClient.delete(url);
  }

  public updatePostDemote(id: string) {
    const url = `portal/api/newsfeed/updatePostByFullcontent/${id}`;

    return this.httpClient.get<string>(url).pipe(
      catchError(error => {
        console.error('Errore durante la chiamata a updatePostDemote:', error);
        return throwError(error);
      })
    );
  }

  public isKNDeletedById(id: string): Observable<Number> {
    return this.httpClient.get<Number>(
      `portal/api/ik/knowledgenugget/isDeleted/${id}`
    );
  }

  public sendDiscoverMore(id: string,object:string) :Observable<any>{
    return this.httpClient.get<Number>(
      `portal/api/ik/${object}/discoverMore/${id}`
    );
  }

  public sendWebinarDiscoverMore(id: string,object:string) :Observable<any>{
    return this.httpClient.get<Number>(
      `webinar/api/ik/${object}/discoverMore/${id}`
    );
  }

  public isIdeaDeletedById(id: string): Observable<Number> {
    return this.httpClient.get<Number>(
      `portal/api/ik/idea/isDeleted/${id}`
    );
  }

  public isSuccessStoryDeletedById(id: string): Observable<Number> {
    return this.httpClient.get<Number>(
      `portal/api/ik/successstory/isDeleted/${id}`
    );
  }

  public isChallengeDeletedById(id: string): Observable<Number> {
    return this.httpClient.get<Number>(
      `portal/api/ik/challenge/isDeleted/${id}`
    );
  }

  public getIkById(id: string): Observable<KnowledgeNugget> {
    return this.httpClient.get<KnowledgeNugget>(
      `portal/api/ik/knowledgenugget/${id}`
    );
  }

  public getIks(): Observable<KnowledgeNugget[]> {
    return this.httpClient.get<KnowledgeNugget[]>(
      `portal/api/ik/knowledgenugget`
    );
  }

  public getIkBadge(id: string): Observable<KnowledgeNuggetBadge> {
    return this.httpClient.get<KnowledgeNuggetBadge>(
      `portal/api/ik/knowledgenugget/badge/${id}`
    );
  }

  public getIkEvaluationByUserId(
    IkId: string,
    userId: string
  ): Observable<Evaluation> {
    return this.httpClient.get<Evaluation>(
      `portal/api/ik/knowledgenugget/${IkId}/user/${userId}/evaluations`
    );
  }

  public getMyIkEvaluation(
    IkId: string,
    type: KMSObject
  ): Observable<Evaluation> {
    const url = this.getBaseUrl(type) + `${IkId}/myevaluations`;

    return this.httpClient.get<Evaluation>(url);
  }

  public getRelatedIK(hashtagId: string): Observable<IkInfo[]> {
    const params = {
      filter: `relatedHashtagsIds/any(t: search.in(t, '${hashtagId}'))`
    };
    return this.searchSvc
      .query("ImpactingKnowledges", "", params)
      .pipe(
        map((searchRes: KMSSearchResults<IkInfo>) =>
          searchRes.results.map(result => result.document)
        )
      );
  }

  public getIkEvaluationByIkId(
    IkId: string,
    type: KMSObject
  ): Observable<Evaluation[]> {
    const url = this.getBaseUrl(type) + `${IkId}/evaluations`;

    return this.httpClient.get<Evaluation[]>(url);
  }

  public getDiffusionRatingByIkId(IkId: string): Observable<KnowledgeNuggetDiffusionRating[]> {
    return this.httpClient.get<KnowledgeNuggetDiffusionRating[]>(
      `portal/api/ik/knowledgenugget/diffusionRating/${IkId}`
    );
  }

  public postSetDiffusionRating(IkId: string, value: number): Observable<KnowledgeNuggetDiffusionRating[]> {
    const payload = {
      item1: IkId,
      item2: value
    };
    return this.httpClient
      .post<KnowledgeNuggetDiffusionRating[]>(`portal/api/ik/knowledgenugget/setDiffusionRating`, payload);
  }

  public getIkByHastagId(hashtagId: string,type:ImpactingKnowledgeType): Observable<IkInfo> {
    return this.httpClient.get<IkInfo>(
      `portal/api/ik/${this.ImpactingKnowledgeTypeToString(type)}/Hashtag/${hashtagId}`
    );
  }

  public evaluate(evaluation: Evaluation, type: KMSObject): Observable<string> {
    const url = this.getBaseUrl(type) + `evaluate`;
    return this.httpClient.post<string>(url, evaluation);
  }

  public unvote(id: string, type: KMSObject): Observable<any> {
    const url = this.getBaseUrl(type) + `evaluation/${id}`;
    return this.httpClient.delete(url);
  }

  public update(item, type: KMSObject): Observable<any> {
    const url = this.getBaseUrl(type) + `update`;
    return this.httpClient.post(url, item);
  }

  public ImpactingKnowledgeTypeToKMSObject(type:ImpactingKnowledgeType): any{
    switch(type){
      case ImpactingKnowledgeType.Challenge:
        return KMSObject.Challenge;
      case ImpactingKnowledgeType.Idea:
        return KMSObject.Idea;
      case ImpactingKnowledgeType.KnowledgeNugget:
        return KMSObject.IK;
      case ImpactingKnowledgeType.SuccessStory:
        return KMSObject.SuccessStory;
      case ImpactingKnowledgeType.Webinar:
        return KMSObject.Webinar;
    }
  }

  public ImpactingKnowledgeTypeToString(type:ImpactingKnowledgeType): string{
    switch(type){
      case ImpactingKnowledgeType.Challenge:
        return "challenge";
      case ImpactingKnowledgeType.Idea:
        return "Idea";
      case ImpactingKnowledgeType.KnowledgeNugget:
        return "knowledgenugget";
      case ImpactingKnowledgeType.SuccessStory:
        return "successstory";
      case ImpactingKnowledgeType.Webinar:
        return "webinar";
    }
  }

  public ServerImpactingKnowledgeTypeKmsObject(type:number): KMSObject{
    switch(type){
      case 1:
        return KMSObject.IK;
      case 2:
        return KMSObject.Challenge;
      case 3:
        return KMSObject.Idea;
      case 4:
        return KMSObject.SuccessStory;
      case 5:
        return KMSObject.Webinar;
    }
  }

  
  public getSuggestionByRootPostId(
    rootPostId: string
  ): Observable<KeyPhraseResult> {
    return this.httpClient.get<KeyPhraseResult>(
      `portal/api/ik/knowledgenugget/suggestions/${rootPostId}`
    );
  }

  public trackEventSS(eventName: string, others: string, id: string, involved: string[]): Observable<any> {
    return this.httpClient.post('portal/api/ik/successstory/'+id+'/traceevent',
    {
      item1: eventName,
      item2: others,
      item3: involved
    });
  }
  public trackEventWebinar(eventName: string, others: string, id: string, involved: string[]): Observable<any> {
    return this.httpClient.post('webinar/api/ik/webinar/'+id+'/traceevent',
    {
      item1: eventName,
      item2: others,
      item3: involved
    });
  }
  public trackEventIdea(eventName: string, others: string, id: string, involved: string[]): Observable<any> {
    return this.httpClient.post('portal/api/ik/idea/'+id+'/traceevent', {
      item1: eventName,
      item2: others,
      item3: involved
    });
  }
  public trackEventChallenge(eventName: string, others: string, id: string, involved: string[]): Observable<any> {
    return this.httpClient.post('portal/api/ik/challenge/'+id+'/traceevent', {
      item1: eventName,
      item2: others,
      item3: involved
    });
  }


  public searchChallenges(
    query: string = "",
    includeExpired = false
  ): Observable<ChallengeInfo[]> {
    // return this.httpClient.get<ChallengeInfo[]>(
    //   `portal/api/ik/challenge/search/${query}/${includeExpired}`
    // );
    return this.searchSvc
      .query<any>("ImpactingKnowledges", query, {
        filter: "type eq 'Challenge' and status eq 'Open'"
      })
      .pipe(
        map(list =>
          list.results.map(
            x =>
              ({
                id: x.document.id,
                name: x.document.name
              } as any)
          )
        )
      );
  }

  public getIdeaById(id: string): Observable<Idea> {
    return this.httpClient.get<Idea>(`portal/api/ik/idea/${id}`);
  }

  public getIdeaBadge(id: string): Observable<KnowledgeNuggetBadge> {
    return this.httpClient.get<KnowledgeNuggetBadge>(
      `portal/api/ik/idea/badge/${id}`
    );
  }

  public getIdeaFollowers(id: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`portal/api/ik/idea/followers/${id}`);
  }
  public getChallengeFollowers(id: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`portal/api/ik/challenge/followers/${id}`);
  }
  public getIkFollowers(id: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`portal/api/ik/knowledgenugget/followers/${id}`);
  }

  searchIK(query: string) {
    const params = {
      // TBD Filters
      top: 10
    };
    return this.searchSvc
      .queryRaw<IkInfo>("ImpactingKnowledges", query, params)
      .pipe(
        map((searchRes: KMSSearchResults<IkInfo>) =>
          searchRes.results.map(result => result.document)
        )
      );
  }

  createSuccessStory(val) {
    return this.httpClient.post("portal/api/ik/successstory/create", val);
  }

  public getChallengeById(challengeId: string): Observable<Challenge> {
    return this.httpClient.get<Challenge>(
      `portal/api/ik/challenge/${challengeId}`
    );
  }

  getSuccessStoryById(id: string) {
    return this.httpClient.get(`portal/api/ik/successstory/${id}`);
  }

  public getChallengeBadge(id: string): Observable<ChallengeBadge> {
    return this.httpClient.get<ChallengeBadge>(
      `portal/api/ik/challenge/badge/${id}`
    );
  }

  public getIdeasByChallengeId(challengeId: string): Observable<Idea[]> {
    return this.httpClient.get<Idea[]>(
      `portal/api/ik/idea/challenge/${challengeId}`
    );
  }

  public getInvolvedNetworksStakeholders(networkIds: string[]) {
    return this.searchSvc
      .queryRaw<any>("Networks", "", {
        filter: `search.in(id, '${networkIds.join(",")}')`,
        selectFields: ["facilitatorsIds,coFacilitatorsIds"],
        queryType: "full"
      })
      .pipe(
        map(payload =>
          payload.results
            .map(x => [
              ...(x.document.facilitatorsIds as string[]),
              ...(x.document.coFacilitatorsIds as string[])
            ])
            .reduce((sum, val) => [...sum, ...val], [])
        )
      );
  }

  public getInvolvedNetworksStakeholdersAndKO(networkIds: string[]) {
    return this.searchSvc
      .queryRaw<any>("Networks", "", {
        filter: `search.in(id, '${networkIds.join(",")}')`,
        selectFields: ["facilitatorsIds,coFacilitatorsIds,koUsersIds"],
        queryType: "full"
      })
      .pipe(
        map(payload =>
          payload.results
            .map(x => [
              ...(x.document.facilitatorsIds as string[]),
              ...(x.document.coFacilitatorsIds as string[]),
              ...(x.document.koUsersIds as string[])
            ])
            .reduce((sum, val) => [...sum, ...val], [])
        )
      );
  }

  public getSimilarContent(
    id: string,
    top: number = 10,
    indexName: string = "ImpactingKnowledges"
  ) {
    // Chiamata
    return this.httpClient.get(
      `search/api/search/suggest/morelikethis/${indexName}/${id}/${top}`
    );
  }

  public postParticipateWebinar(participants: number, webinarId: string) {
    const payload = {
      participants: participants,
      objectId: webinarId
    };
    this.httpClient
      .post(`webinar/api/ik/webinar/participatewebinar`, payload)
      .toPromise();
  }

  private getBaseUrl(type: KMSObject) {
    switch (type) {
      case KMSObject.IK:
        return `portal/api/ik/knowledgenugget/`;
      case KMSObject.Idea:
        return `portal/api/ik/idea/`;
      case KMSObject.Webinar:
        return `webinar/api/ik/webinar/`;
      case KMSObject.SuccessStory:
        return `portal/api/ik/successstory/`;
      case KMSObject.Challenge:
        return `portal/api/ik/challenge/`;
    }
  }
}
