import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import * as signalR from "@aspnet/signalr";
import { map, filter, tap, first, take } from "rxjs/operators";
import { ConfigService } from "@ngx-config/core";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import {
  OperationResultStatus,
  UserNotification,
  NotificationSetting,
  EntityType
} from "../shared/entities";
import { SnackbarService } from "ngx-snackbar";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SwPush } from "@angular/service-worker";
import { Router } from "@angular/router";
import { ConfigurationService } from "./configuration.service";

export interface INotification {
  OperationId: string;
  SenderId: boolean;
  Result: OperationResultStatus;
  Command: string;
  Details: string;
}
export interface INotificationAction {
  action: string;
}
@Injectable({
  providedIn: "root"
})
export class NotificationService {
  private notificationsSubject$: BehaviorSubject<INotification[]>;
  private notificationsActionSubject$: BehaviorSubject<INotificationAction>;
  private connection: signalR.HubConnection;
  private hubCommands = ["operationresult", "notify"];
  private notificationsList$: Observable<UserNotification[]>;
  private notifications: UserNotification[];
  public readNot = new Subject();
  private _subscription: PushSubscription;

  constructor(
    private configSvc: ConfigurationService,
    private readonly config: ConfigService,
    private readonly authSvc: MsAdalAngular6Service,
    private readonly snackbarSvc: SnackbarService,
    private readonly http: HttpClient,
    private swPush: SwPush,
    private readonly router: Router
  ) {
    this.notificationsSubject$ = new BehaviorSubject<INotification[]>([]);
    this.notificationsActionSubject$ = new BehaviorSubject<INotificationAction>(
      null
    );

    this.lastNotification$
    .pipe(
      filter(op => {
        return !!op;
      }),
      filter(op => {
        return op.Command !== "notify";
      })
    )
    .subscribe(op => {
      if (op.Result === OperationResultStatus.Success) {
        if (op.Command === 'CreateLele') {
          this.showSuccess(
            `${op.Command} ID : ${op.Details}`
          );
        } else {
          this.showSuccess(
            `${op.Command}: ${OperationResultStatus[op.Result]}`
          );
        }
      } else {
        if (
          this.config.getSettings().showErrorDetails &&
          op.Result === OperationResultStatus.Failure
        ) {
          this.showError(
            `${op.Command}: ${OperationResultStatus[op.Result]}`
          );
        }
      }
    });



    this.configSvc.assetsConfig
      .pipe(
        filter(key => key != null),
        take(1)
      )
      .subscribe(() => {
        this.registerNotifications();
      });
  }


  public async registerNotifications() {
    // Remove of the old SW to allow the new one to register on the root scope
    const oldSw = (await navigator.serviceWorker.getRegistrations()).find(x =>
      x.active.scriptURL.endsWith("notification-sw.js")
    );
    if (oldSw) {
      oldSw.unregister();
    }

    if (this.swPush.isEnabled) {
      const { baseUrl, pushUrl } = this.config.getSettings();
      this.swPush.subscription.subscribe(subscription => {
        if (!subscription) {
          this.swPush
            .requestSubscription({
              serverPublicKey: this.configSvc.pushKey
            })
            .then(newSubscription => {
              this._subscription = newSubscription;
              this.registerSubscription(newSubscription, baseUrl, pushUrl);
            });
        } else {
          this._subscription = subscription;
          this.registerSubscription(subscription, baseUrl, pushUrl);
        }
      });

      this.swPush.notificationClicks.subscribe(payload => {
        switch (payload.action) {
          case "goto":
            this.navigateToNotification(
              payload.notification.data.sourceObjectType,
              payload.notification.data.sourceObjectId
            );
            break;
          case "all":
          default:
            this.router.navigate(["/notifications"]);
            break;
        }
      });
      // this.swPush.messages.subscribe(msg => console.log(msg));
    }
  }

  private navigateToNotification(sourceObjectType: any, sourceObjectId: any) {
    switch (sourceObjectType) {
      case EntityType.Challenge:
        this.router.navigate(["/challenge", sourceObjectId]);
        break;
      case EntityType.Post:
        this.router.navigate(["/conversation", sourceObjectId]);
        break;
      case EntityType.Hashtag:
        this.router.navigate(["/hashtag", sourceObjectId]);
        break;
      case EntityType.KnowledgeNugget:
        this.router.navigate(["/ik", sourceObjectId]);
        break;
      case EntityType.Idea:
        this.router.navigate(["/idea", sourceObjectId]);
        break;
      case EntityType.Network:
        this.router.navigate(["/network", sourceObjectId]);
        break;
      case EntityType.SuccessStory:
        this.router.navigate(["/success-story/view", sourceObjectId]);
        break;
      case EntityType.User:
        this.router.navigate(["/profile", sourceObjectId]);
        break;
    }
  }

  private async registerSubscription(
    subscription: PushSubscription,
    baseUrl: any,
    pushUrl: any
  ) {
    const payload = subscription.toJSON();
    const postObj = {
      endpoint: payload.endpoint,
      p256dh: payload.keys.p256dh,
      auth: payload.keys.auth
    };

    this.http
      .post(`${baseUrl}${pushUrl}/registration`, postObj, {
        responseType: "text"
      })
      .subscribe(response => {});
  }

  get notificationsAction$() {
    return this.notificationsActionSubject$.asObservable();
  }

  get notifications$() {
    return this.notificationsSubject$.asObservable();
  }

  get lastNotification$() {
    return this.notificationsSubject$.pipe(map(list => list.slice(-1).pop()));
  }

  get notificationList$() {
    return this.notificationsList$;
  }

  set notificationList$(notifications: Observable<UserNotification[]>) {
    this.notificationsList$ = notifications;
  }

  get groupedNotifications() {
    return this.notifications;
  }

  set groupedNotifications(notifications: UserNotification[]) {
    this.notifications = notifications;
  }

  public async connect() {
    const { apiUrlPrefix } = this.config.getSettings();
    // See https://docs.microsoft.com/en-us/aspnet/core/tutorials/signalr-typescript-webpack?view=aspnetcore-2.1&tabs=visual-studio
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrlPrefix}hub/notifications`, {
        accessTokenFactory: () => this.authSvc.accessToken
      })
      //.configureLogging(signalR.LogLevel.Trace)
      .build();
    // Listen to all configured hub commands
    this.hubCommands.forEach(command =>
      this.connection.on(command, message => {
        const list = [...this.notificationsSubject$.value, message];
        this.notificationsSubject$.next(list);
      })
    );
    await this.innerStart();
  }

  private async innerStart() {
    try {
      await this.connection.start();
      //.then(m => {
      //  console.info("Signalr Connection done!");
      //});
      this.connection.onclose(async (error?: Error) => {
        this.showError(`Reconnecting notifications...`);
        this.showError(`${error}`);
        await this.innerStart();
      });
    } catch (error) {
      console.error(error.message);
      setTimeout(() => {
        this.showError(
          this.config.getSettings().showErrorDetails
            ? "ERROR: cannot connect to notification service!"
            : this.config.getSettings().notificationErrorMessage
        );
      }, 1000);
    }
  }

  private showSuccess(msg: string) {
    if (this.config.getSettings().showSuccessMessages) {
      this.snackbarSvc.add({
        msg,
        background: "#33691E",
        action: { text: null }
      });
    }
  }

  private showError(msg: string) {
    if (this.config.getSettings().showSuccessMessages) {
      this.snackbarSvc.add({
        msg,
        background: "red",
        action: { text: null }
      });
    }
  }

  public showDetailsError(msg: any | string) {
    if (typeof msg === "string") {
      alert(msg);
    } else {
    }
    alert(`Operation failed, please try again later \n\n technical details : \n ${JSON.stringify(msg.error)}`);
  }

  public displayError(msg: any | string) {
   if(msg){
    alert(msg.error.message)
   }
  }



  public addNotification(notification: INotification) {
    const list = [...this.notificationsSubject$.value, notification];
    this.notificationsSubject$.next(list);
  }



  public waitForTransactionResult(transactionId: string , lele? : boolean ) {
    return this.notificationsSubject$.pipe(
      first(list => list.some(x => x.OperationId === transactionId)),
      map(list => list.find(x => x.OperationId === transactionId)),
      tap(notification => {
        if (notification) {
          if (notification.Result === OperationResultStatus.Success) {
            if(lele)
              {
             this.showSuccess(
                `This draft will remain active for 60 days, then the lesson will be automatically deleted`
              )
            }
          } else {
            throw new Error(
              notification.Details
                ? notification.Details
                : "Operation Failed, Please Retry"
            );
          }
        }
      }),
      filter(
        notification =>
          notification && notification.Result === OperationResultStatus.Success
      ),
      map(() => true)
    );
  }

  public getNotifications(): Observable<UserNotification[]> {
    return this.http.get<UserNotification[]>(`portal/api/user/notification`);
  }

  public readNotifications(idList: Array<string>) {
    return this.http.post<UserNotification[]>(
      `portal/api/user/notification/mark`,
      idList
    );
  }

  public setNotificationsSettings(
    userId: string,
    notificationList: NotificationSetting[]
  ) {
    return this.http.post<any>(
      `portal/api/user/${userId}/notificationsettings`,
      notificationList
    );
  }

  public setProfileSettings(
    userId: string,
    user: any,
    notificationList: NotificationSetting[]
  ) {
    return this.http.post<any>(
      `portal/api/user/profile/${userId}/settingsupdate`,
      { item1: user, item2: notificationList }
    );
  }

  public getNotificationsSettings(
    userId: string
  ): Observable<NotificationSetting[]> {
    return this.http.get<NotificationSetting[]>(
      `portal/api/user/${userId}/notificationsettings`
    );
  }
  public getUnreadNotification() {
    return this.http.get<any>(`portal/api/User/notification/unreadcount`);
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
