import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Observable, combineLatest, from, of } from "rxjs";
import { map, mergeMap, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { ConfigurationService } from "src/app/services/configuration.service";
import { HashtagService } from "src/app/services/hashtag.service";
import { IkService } from "src/app/services/ik.service";
import { LoggerService } from "src/app/services/logger.service";
import { NetworkService } from "src/app/services/network.service";
import { NewsfeedService } from "src/app/services/newsfeed.service";
import {
  KMSSearchResults,
  SearchResult,
  SearchService,
} from "src/app/services/search.service";
import { UserService } from "src/app/services/user.service";
import { WebinarsService } from "src/app/services/webinars.service";
import { BaseComponent } from "src/app/shared/base.component";
import {
  AISearchTelemetry,
  ExternalUser,
  KMSObject,
  NetworkInfo,
  SuccessStory,
  UserCategory,
} from "src/app/shared/entities";
import { Guid } from "src/app/shared/utils";

@Component({
  selector: "kms-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SearchBarComponent extends BaseComponent implements OnInit {
  @ViewChild("inputBar", { static: true })
  inputBar: ElementRef<HTMLInputElement>;

  textControl = new FormControl();
  @HostBinding("class.spinner")
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataSource: Observable<any>;
  suggestions$: Observable<any>;
  typehead$: Observable<any>;
  suggestions = [];
  sds = [];
  totalResults = 0;
  params = {
    searchFields: ["type", "name", "description"],
  };
  protected _active: TypeaheadMatch;
  isMobile = false;
  externalUser: ExternalUser;
  queryBeforePressEnter;

  searchSuggestionTimeoutFunction = null;
  searchSuggestionTimeoutInMS = 1200;

  public mimeTypes = {
    pdf: /pdf/i,
    image: /image/i,
    word: /word|\.document/i,
    excel: /excel|\.sheet/i,
    powerpoint: /powerpoint|\.presentation/i,
    generic: /octet-stream/i,
    video: /video/i,
  };

  public resultSets = [
    { set: "resultSet1", totalHits: 0, havingCategories: [] },
    { set: "resultSet2", totalHits: 0, havingCategories: [] },
    { set: "resultSet3", totalHits: 0, havingCategories: [] },
  ];

  public totalResultSetHits: number = 0;

  constructor(
    private readonly searchSvc: SearchService,
    private readonly router: Router,
    private readonly userSvc: UserService,
    private readonly cd: ChangeDetectorRef,
    private readonly configurationSvc: ConfigurationService,
    private readonly logger: LoggerService,
    private readonly networkSvc: NetworkService,
    private newfeedSvc: NewsfeedService,
    private ikSvc: IkService,
    private webinarSvc: WebinarsService,
    private hashtagSvc: HashtagService
  ) {
    super();
    const query$ = Observable.create((observer) => {
      // Runs on every search
      if (this.textControl.value) {
        if (localStorage.getItem("lastSearch")) {
          const actualHour = new Date();
          const lastSearchDate = new Date(localStorage.getItem("lastSearch"));
          const diff = Math.abs(<any>actualHour - <any>lastSearchDate) / 36e5;
          if (diff >= 1) {
            this.logger.event("search.query");
            localStorage.setItem("lastSearch", `${actualHour}`);
          }
        } else {
          this.logger.event("search.query");
          localStorage.setItem("lastSearch", `${new Date()}`);
        }
      }
      observer.next(this.textControl.value);
    });

    this.dataSource = query$.pipe(
      // filter((query: string) => !!query),
      takeUntil(this.ngUnsubscribe),
      switchMap((query: string) => {
        this.resultSets[0].totalHits = 0;
        this.resultSets[1].totalHits = 0;
        this.resultSets[2].totalHits = 0;

        this.resultSets[0].havingCategories = [];
        this.resultSets[1].havingCategories = [];
        this.resultSets[2].havingCategories = [];

        if (!query) {
          this.totalResults = 0;
          return of([]);
        } else {
          var obj = this.query('"' + query.trim() + '"', this.resultSets[0]);
          var obj2 = this.query(
            query.trim().replace(/ /g, "+"),
            this.resultSets[1]
          );
          var obj3 = this.query(query.trim(), this.resultSets[2]);

          var rt = combineLatest([obj, obj2, obj3]);
          return rt;
        }
      }),
      map((resSets: any) => resSets.flat()),
      map((resSets: any) => resSets.filter((x: any) => !!x.document?.type)),
      tap((x: any) => {
        this.resultSets[0].totalHits = x.filter(
          (rs) => rs.resultSet == "resultSet1"
        ).length;
        this.resultSets[1].totalHits = x.filter(
          (rs) => rs.resultSet == "resultSet2"
        ).length;
        this.resultSets[2].totalHits = x.filter(
          (rs) => rs.resultSet == "resultSet3"
        ).length;
      }),
      tap(
        (x) =>
          (this.totalResultSetHits = this.resultSets.reduce(
            (a, b) => a + b.totalHits,
            0
          ))
      ),
      map((prevResults) => {
        return this.totalResultSetHits != 0
          ? prevResults
          : !!this.textControl.value
          ? [{}]
          : [];
      }),
      map((prevResults: any) => {
        if (!prevResults) return prevResults;

        const resultSet3 = prevResults.filter(
          (x) => x.resultSet === "resultSet3"
        );

        let resultSet2 = prevResults.filter(
          (x) => x.resultSet === "resultSet2"
        );

        const resultSet1 = prevResults.filter(
          (x) => x.resultSet === "resultSet1"
        );

        resultSet2 = resultSet2.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.document.id === value.document.id)
        );

        const resSet1And2 = [
          ...resultSet1,
          ...resultSet2.filter(
            (rs: any) =>
              !resultSet1?.some(
                ({ document }) => rs.document.id === document.id
              )
          ),
        ];

        let resSet1And2And3 = [
          ...resSet1And2,
          ...resultSet3.filter(
            (rs: any) =>
              !resSet1And2?.some(
                ({ document }) => rs.document.id === document.id
              )
          ),
        ];

        this.resultSets[0].totalHits = resSet1And2And3.filter(
          (x) => x.resultSet === "resultSet1"
        ).length;
        this.resultSets[1].totalHits = resSet1And2And3.filter(
          (x) => x.resultSet === "resultSet2"
        ).length;
        this.resultSets[2].totalHits = resSet1And2And3.filter(
          (x) => x.resultSet === "resultSet3"
        ).length;

        this.resultSets[2].set = "invalid";

        this.resultSets[1].totalHits = resSet1And2And3.filter(
          (x) => x.resultSet === "resultSet2" || x.resultSet === "resultSet3"
        ).length;

        resSet1And2And3 = resSet1And2And3.map((rs) => {
          if (rs.resultSet == "resultSet3")
            return {
              ...rs,
              resultSet: "resultSet2",
            };

          return {
            ...rs,
          };
        });

        return resSet1And2And3;
      }),
      tap((results: any) => {
        if (!results) return;
        results
          .filter((dc) => dc.resultSet == this.resultSets[0].set)
          .forEach((rs: any) => {
            this.resultSets[0].havingCategories.push(rs.document.type);
          });
        results
          .filter((dc) => dc.resultSet == this.resultSets[1].set)
          .forEach((rs: any) => {
            this.resultSets[1].havingCategories.push(rs.document.type);
          });
        results
          .filter((dc) => dc.resultSet == this.resultSets[2].set)
          .forEach((rs: any) => {
            this.resultSets[2].havingCategories.push(rs.document.type);
          });

        this.resultSets[0].havingCategories =
          this.resultSets[0].havingCategories.filter(function (
            elem,
            index,
            self
          ) {
            return index === self.indexOf(elem);
          });

        this.resultSets[1].havingCategories =
          this.resultSets[1].havingCategories.filter(function (
            elem,
            index,
            self
          ) {
            return index === self.indexOf(elem);
          });

        this.resultSets[2].havingCategories =
          this.resultSets[2].havingCategories.filter(function (
            elem,
            index,
            self
          ) {
            return index === self.indexOf(elem);
          });
      }),
      tap((x) => {
        this.totalResultSetHits = this.resultSets.reduce(
          (a, b) => a + b.totalHits,
          0
        );
      })
    );

    this.suggestions$ = this.textControl.valueChanges.pipe(
      // filter((query: string) => !!query),
      takeUntil(this.ngUnsubscribe),
      mergeMap((query: string) => {
        var obj: Observable<{}> = of([]);
        if (query) {
          if (this.isGuest()) {
            obj = this.searchSvc.guestAutocomplete(
              "Default",
              "default",
              query,
              this.params
            );
          } else {
            obj = this.searchSvc.autocomplete(
              "Default",
              "default",
              query,
              this.params
            );
          }
        }
        return obj;
      })
    );

    this.dataSource.subscribe(() => {
      this.cd.markForCheck();
    });
  }

  async ngOnInit() {
    if (
      this.userSvc.userProfile.isExternalConsultant == true &&
      this.userSvc.isGuest == false
    ) {
      await this.getExternalUser();
    }

    this.configurationSvc.isMobile.subscribe((el) => {
      this.isMobile = el;
      this.cd.markForCheck();
    });
    this.suggestions$.subscribe((value) => {
      if (this.textControl.value) {
        const words = this.textControl.value.trim().split(" ");
        this.suggestions = value.value
          ? value.value.filter((t) => t.text !== words[words.length - 1])
          : [];
        this.cd.markForCheck();
      }
    });
  }

  get show() {
    return (
      this.userSvc.userProfile &&
      (this.userSvc.userCategory === UserCategory.Employee ||
        this.userSvc.userCategory === UserCategory.External ||
        this.userSvc.userCategory === UserCategory.Guest)
    );
  }

  isGuest() {
    return (
      this.userSvc.userProfile &&
      this.userSvc.userCategory === UserCategory.Guest
    );
  }

  get minLength() {
    return this.isMobile ? null : 0;
  }

  get inputType() {
    return this.isMobile ? "search" : "text";
  }

  query(
    query: string,
    resSet: { set: any; totalHits: number }
  ): Observable<any> {
    this.queryBeforePressEnter = query;
    const self = this;
    return from(
      new Promise<KMSSearchResults<unknown>>(function (resolve, reject) {
        // if (self.searchSuggestionTimeoutFunction !== null) {
        //   clearTimeout(self.searchSuggestionTimeoutFunction);
        // }
        self.searchSuggestionTimeoutFunction = window.setTimeout(() => {
          self.searchSuggestionTimeoutFunction = null;

          var searchResults$: Observable<KMSSearchResults<unknown>>;

          if (self.isGuest()) {
            searchResults$ = self.searchSvc.guestQuery("Default", query, {
              // orderBy: ["modified", "created"],
              top: 15,
              filter: `type ne 'Booking'`,
              includeTotalResultCount: true,
              highlightPreTag: "<mark>",
              highlightPostTag: "</mark>",
              highlightFields: ["author", "name", "description"],
            });
          } else {
            searchResults$ = self.searchSvc.query("Default", query, {
              // orderBy: ["modified", "created"],
              top: 15,
              filter: `type ne 'Booking'`,
              includeTotalResultCount: true,
              highlightPreTag: "<mark>",
              highlightPostTag: "</mark>",
              highlightFields: ["author", "name", "description"],
            });
          }

          searchResults$
            .pipe(
              tap((reply) => (self.totalResults = reply.count)),
              switchMap((results: KMSSearchResults<unknown>) =>
                self.filterSearchResults(results)
              ),
              map((reply) => self.sliceResults(reply.results)),
              map((res) => {
                return res.map((r) => {
                  return {
                    ...r,
                    resultSet: resSet.set,
                  };
                });
              }),
              tap((res) => {
                resSet.totalHits = res.length;
              })
            )
            .subscribe((results) => {
              //QUI
              // console.log(results);

              resolve(results);
            });
        }, self.searchSuggestionTimeoutInMS);
      })
    );
  }

  autocomplete(text: string, ev: Event) {
    this.searchSvc
      .saveSuggestionClickTelemetry(this.userSvc.userId, text)
      .subscribe(() => {});
    const textParts = this.textControl.value.split(" ");
    textParts.splice(-1, 1);
    this.textControl.setValue(
      `${textParts.length > 0 ? textParts.join(" ") + " " : ""}${text}`
    );
    this.inputBar.nativeElement.focus();
    this.cd.markForCheck();
    ev.preventDefault();
    ev.stopImmediatePropagation();
  }

  public formatSearchText(txt: string, resSet: string) {
    if (resSet === "resultSet1") return `\"${txt}\"`;
    if (resSet === "resultSet3") return `${txt}`;

    return txt?.replace(/ /g, " OR ") + "...";
  }

  setLoading(e: boolean): void {
    if (this.textControl.value && this.textControl.value.length > 0) {
      this.typeaheadLoading = e;
      this.cd.markForCheck();
    } else if (!this.textControl.value) {
      this.typeaheadLoading = false;
      this.cd.markForCheck();
    }
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {}

  isActive(value: TypeaheadMatch): boolean {
    return this._active === value;
  }

  link(type, id) {
    switch (type) {
      case "KnowledgeNugget": {
        return "/ik/" + id;
      }
      case "ImpactingKnowledge": {
        return "/ik/" + id;
      }
      case "SuccessStory": {
        return "/success-story/view/" + id;
      }
      case "Idea": {
        return "/idea/" + id;
      }
      case "Challenge": {
        return "/challenge/" + id;
      }
      default: {
        return "/ik/" + id;
      }
    }
  }

  async getExternalUser(): Promise<void> {
    this.externalUser = await this.userSvc
      .getExternalUserByUPN(this.userSvc.userProfile.upn)
      .toPromise();
  }

  isIk(type: any) {
    return /KnowledgeNugget|ImpactingKnowledge|Challenge|Idea|SuccessStory/i.test(
      type
    );
  }

  selectActive(value: TypeaheadMatch): void {
    this._active = value;
  }

  selectMatch(value: TypeaheadMatch, e: Event = void 0): boolean {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    // this.parent.changeModel(value);
    // setTimeout(() => this.parent.typeaheadOnSelect.emit(value), 0);

    return false;
  }

  goToFullPage(e: Event) {
    this.router.navigate(["/search"], {
      queryParams: { query: this.queryBeforePressEnter },
    });
    this.textControl.setValue(null);
    this.typeaheadLoading = false;
    this._active = null;
    this.cd.markForCheck();
    e.stopImmediatePropagation();
    e.preventDefault();
  }

  getDescription(model: any, highlights: any) {
    return highlights.description
      ? highlights.description.join("")
      : model.description.slice(0, 50);
  }

  sendIkTelemetryAndResetSearch(id: Guid) {
    this.searchSvc
      .saveIKClickTelemetry(this.userSvc.userId, id)
      .subscribe(() => {});
    this.resetSearch();
  }

  resetSearch() {
    this.textControl.setValue(null);
    this.cd.detectChanges();
  }

  getAvatarDefault(type) {
    switch (type) {
      case "KnowledgeNugget": {
        return KMSObject.IK;
      }
      case "ImpactingKnowledge": {
        return KMSObject.IK;
      }
      case "SuccessStory": {
        return KMSObject.SuccessStory;
      }
      case "Idea": {
        return KMSObject.Idea;
      }
      case "Challenge": {
        return KMSObject.Challenge;
      }
      default: {
        return KMSObject.IK;
      }
    }
  }

  openAttachment(url: string) {
    window.open(encodeURI(url) + "?web=1", "_blank");
  }

  sortResult(x) {
    const ordering = {}; // map for efficient lookup of sortIndex
    const sortOrder = [];
    // The search returns an array ordered by score so we can assume that the first element is the one with the highest score
    for (let i = 0; i < x.length; i++) {
      if (!sortOrder.find((type) => type === x[i].document.type)) {
        sortOrder.push(x[i].document.type);
      }
    }
    for (let i = 0; i < sortOrder.length; i++) {
      ordering[sortOrder[i]] = i;
    }

    x.sort(function (a, b) {
      return (
        ordering[a.document.type] - ordering[b.document.type] ||
        b.score - a.score
      );
    });
    return x;
  }

  sliceResults(x) {
    var telemetry = new AISearchTelemetry();
    telemetry.text = this.textControl.value;
    telemetry.suggestions = [];
    telemetry.iks = [];
    x.forEach((k) => {
      if (this.isIk(k.document.type)) {
        telemetry.iks.push(k.document.id);
      }
    });
    this.suggestions.forEach((k) => {
      telemetry.suggestions.push(k.text);
    });

    // console.log(JSON.stringify(telemetry));
    this.searchSvc
      .saveSearchTelemetry(this.userSvc.userId, telemetry)
      .subscribe(() => {});

    const keys = [];

    if (x.length > 0) {
      const res = x.reduce((obj, value: any) => {
        const key = `type${value.document.type}`;
        if (obj[key] == null) {
          obj[key] = [];
        }
        obj[key].push(value);
        if (!keys.find((k) => k === key)) {
          keys.push(key);
        }
        return obj;
      }, {});

      let ret = [];
      // x = [];
      keys.forEach((key) => {
        if (res[key] && res[key].length) {
          ret = [...ret, ...res[key].slice(0, 5)];
        }
      });
      // const ret = [];
      // keys.forEach(key => {
      //   ret.push(x.results[key].slice(0, 5));
      // });
      //  this.getExternalUser()
      return this.sortResult(ret);
    } else {
      return [
        {
          document: {
            type: null,
          },
        },
      ];
    }
  }

  getLabel(val) {
    switch (val.value) {
      case "Post": {
        return "Posts";
      }
      case "Question": {
        return "Questions";
      }
      case "User": {
        return "Users";
      }
      case "Hashtag": {
        return "Hashtags";
      }
      case "Network": {
        return "Networks";
      }
      case "ImpactingKnowledge": {
        return "Impacting Knowledges";
      }
      case "SuccessStory": {
        return "Success Stories";
      }
      case "Challenge": {
        return "Challenges";
      }
      case "Idea": {
        return "Ideas";
      }
      case "Attachment": {
        return "Attachments";
      }
      case "KnowledgeNugget": {
        return "Knowledge Nuggets";
      }
      case "Webinar": {
        return "Past KM Webinars";
      }
      case "LessonLearned": {
        return "Lesson Learned";
      }
    }
  }

  async filterSearchResults(
    results: KMSSearchResults<unknown>
  ): Promise<KMSSearchResults<unknown>> {
    //se l'indice di ricerca è quello di defult devo implementare una ricerca per ogni tipo di oggettto
    // atenzione il metodo array.filter non funzona con le promises o richieste asincrone
    if (
      this.userSvc.userProfile.isExternalConsultant == true &&
      this.userSvc.isGuest == false
    ) {
      // console.log(this.indexName$.value)

      let resultSupport: SearchResult<unknown>[] = [];
      for (const result of results.results) {
        //per le network sfrutto il newfeedService
        if (
          (<any>result)?.document?.type == "Network" &&
          (await this.searchInNetworks((<any>result).document?.id).catch(
            (err) => {}
          ))
        ) {
          resultSupport.push(result);

          //se l'oggetto è un utente lo ritorno direttamente
        } else if (
          (<any>result)?.document?.type == "Post" &&
          (await this.searchInPosts((<any>result).document.id).catch(
            (err) => {}
          ))
        ) {
          resultSupport.push(result);
        } else if (
          (<any>result)?.document?.type == "Webinar" &&
          (await this.searchInWebinars((<any>result).document.id).catch(
            (err) => {}
          ))
        ) {
          resultSupport.push(result);
        } else if (
          (<any>result)?.document?.type == "Challenge" &&
          (await this.searchInChallenges((<any>result).document.id).catch(
            (err) => {}
          ))
        ) {
          resultSupport.push(result);
        } else if (
          (<any>result)?.document?.type == "Idea" &&
          (await this.searchInIdeas((<any>result).document.id).catch(
            (err) => {}
          ))
        ) {
          resultSupport.push(result);
        } else if (
          (<any>result)?.document?.type == "KnowledgeNugget" &&
          (await this.searchInKnowledgeNuggets((<any>result).document.id).catch(
            (err) => {}
          ))
        ) {
          resultSupport.push(result);
        }
        //  else if (
        //   (<any>result)?.document?.type == "Attachment" &&
        //   (await this.searchInWebinars((<any>result).document.id).catch(err => {}))
        // ) {
        //   resultSupport.push(result);
        // }
        else if (
          (<any>result)?.document?.type == "Hashtag" &&
          (await this.searchInHashtags((<any>result).document.id).catch(
            (err) => {}
          ))
        ) {
          resultSupport.push(result);
        } else if (
          (<any>result)?.document?.type == "SuccessStory" &&
          (await this.searchInSuccessStories((<any>result).document.id).catch(
            (err) => {}
          ))
        ) {
          resultSupport.push(result);
        } else if ((<any>result)?.document?.type == "User") {
          resultSupport.push(result);
        } else if (
          (<any>result)?.document?.type == "LessonLearned" &&
          this.userSvc.externalUserProfile?.isLeleEnabled
        ) {
          resultSupport.push(result);
        }
      }
      results.results = resultSupport;
    }
    return results;
  }

  async searchInNetworks(id: string): Promise<boolean> {
    let network = await this.networkSvc.getNetworkById(id).toPromise();
    // console.log( this.isInsideExternalUserNetworks(network?.networkId))
    return this.isInsideExternalUserNetworks(network?.networkId);
  }

  async searchInIks(id: string): Promise<boolean> {
    let ik = await this.ikSvc.getIkById(id).toPromise();
    // console.log(ik);
    return this.checkNetworkInfo(ik?.promotingNetworks);
  }

  async searchInChallenges(id: string): Promise<boolean> {
    let ik = await this.ikSvc.getChallengeById(id).toPromise();
    // console.log(ik);
    return this.checkNetworkInfo(ik?.promotingNetworks);
  }

  async searchInIdeas(id: string): Promise<boolean> {
    let ik = await this.ikSvc.getIdeaById(id).toPromise();
    // console.log(ik);
    return this.checkNetworkInfo(ik?.promotingNetworks);
  }

  async searchInKnowledgeNuggets(id: string): Promise<boolean> {
    let ik = await this.ikSvc.getIkById(id).toPromise();
    // console.log(ik);
    return this.checkNetworkInfo(ik?.promotingNetworks);
  }

  // async searchInAttachments(id: string): Promise<boolean> {
  //   let ik = await this.ikSvc.(id).toPromise();
  //   // console.log(ik);
  //   return this.checkNetworkInfo(ik?.promotingNetworks);
  // }

  checkNetworkInfo(networks: NetworkInfo[]): boolean {
    let response = true;
    if (networks && networks.length > 0) {
      response = false;
      networks.forEach((network) => {
        if (this.isInsideExternalUserNetworks(network.networkId)) {
          response = true;
        }
      });

      return response ? true : false;
    }
    return true;
  }

  async searchInSuccessStories(id: string): Promise<boolean> {
    let ik = await this.ikSvc.getSuccessStoryById(id).toPromise();
    // console.log(ik);
    return this.checkNetworkInfo((<SuccessStory>ik)?.promotingNetworks);
  }

  async searchInHashtags(id: string): Promise<boolean> {
    let hashtag = await this.hashtagSvc.getHashtagInfoById(id).toPromise();
    // console.log(ik);
    return this.checkAudienceIds(hashtag?.promotingNetworksIds);
  }

  async searchInWebinars(id: string): Promise<boolean> {
    let ik = await this.webinarSvc.getVideoByid(id);
    // console.log(ik);
    return this.checkNetworkInfo(ik?.promotingNetworks);
  }

  async searchInPosts(id: string): Promise<boolean> {
    let newsfeed = await this.newfeedSvc.getConversationById(id).toPromise();
    // console.log("ciao " + this.checkAudienceIds(newsfeed.rootPost?.audience));
    return this.checkAudienceIds(newsfeed?.rootPost.audience);
  }

  checkAudienceIds(networks: string[]): boolean {
    let response = true;
    if (networks && networks.length > 0) {
      response = false;
      networks.forEach((network) => {
        if (this.isInsideExternalUserNetworks(network)) {
          response = true;
        }
      });

      return response ? true : false;
    }
    return true;
  }

  //controllo se l'id della network si trova dentro la lista delle network seguite dall'utente
  isInsideExternalUserNetworks(networkId: string): boolean {
    return (
      this.userSvc.externalUserProfile.networks.find(
        (network) => network.item1 == networkId
      ) !== undefined
    );
  }
}
